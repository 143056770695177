
import { defineComponent, ref, onMounted } from "vue";

import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

import { ElLoading } from "element-plus";
import moment from "moment";
import { Action } from "vuex-module-decorators";
import { object } from "yup/lib/locale";

export default defineComponent({
  name: "FinancialSkillsModules",

  async mounted() {
    this.init();
  },
  data() {
    const modules = [
      {
        label: "1.1 Cash Flow",
        url: "fa-skill/household/1-1_fam_trp_cash_flow.pdf",
        course: "Cash Flow",
        categories: ["FA Modules HH", "PFP Modules"],
      },
      {
        label: "1.2 Budgeting",
        url: "fa-skill/household/1-2_fam_budgeting.pdf",
        course: "Cash Flow",
        categories: ["FA Modules HH", "PFP Modules"],
      },
      {
        label: "1.3 Saving",
        url: "fa-skill/household/1-3_fam_saving.pdf",
        course: "Cash Flow",
        categories: ["FA Modules HH", "PFP Modules"],
      },
      {
        label: "1.4 Emergency Fund",
        url: "fa-skill/household/1-4_fam_emergency_fund.pdf",
        course: "Cash Flow",
        categories: ["FA Modules HH"],
      },
      {
        label: "1.5 Expenses",
        url: "fa-skill/household/1-5_fam_expenses.pdf",
        course: "Cash Flow",
        categories: ["FA Modules HH"],
      },
      {
        label: "1.6 Debt Management",
        url: "fa-skill/household/1-6_fam_trp_debt_management.pdf",
        course: "Cash Flow",
        categories: ["FA Modules HH"],
      },
      {
        label: "1.7 Restructuring Rescheduling",
        url: "fa-skill/household/1-7_fam_trp_restructuring_rescheduling.pdf",
        course: "Cash Flow",
        categories: ["FA Modules HH", "PFP Modules"],
      },
      {
        label: "2.1 Credit Assessment",
        url:
          "fa-skill/household/2-1_fam_borrowing_basics_credit_assessment_5c.pdf",
        course: "Borrowing Basics",
        categories: ["FA Modules HH"],
      },
      {
        label: "2.2 Credit Scores",
        url: "fa-skill/household/2-2_fam_borrowing_basics_credit_scores.pdf",
        course: "Borrowing Basics",
        categories: ["FA Modules HH"],
      },
      {
        label: "2.3 Debt Service Ratio",
        url:
          "fa-skill/household/2-3_fam_borrowing_basics_debt_service_ratio.pdf",
        course: "Borrowing Basics",
        categories: ["FA Modules HH"],
      },
      {
        label: "3.1 Credit Card, Overdraft Personal Loan and Hire Purchase",
        url:
          "fa-skill/household/3-1_fam_credit_card_overdraft_personal_loan_and_hire_purchase.pdf",
        course: "Financial Facilities",
        categories: ["FA Modules HH"],
      },
      {
        label: "3.2 Housing Loan",
        url: "fa-skill/household/3-2_fam_housing_loan.pdf",
        course: "Financial Facilities",
        categories: ["FA Modules HH"],
      },
      {
        label: "4. Banking Products and Services Conventional and Islamic",
        url:
          "fa-skill/household/4_fam_banking_products_and_services_conventional_and_islamic.pdf",
        course: "General",
        categories: ["FA Modules HH"],
      },
      {
        label: "5. Banking Principles Conventional and Islamic",
        url:
          "fa-skill/household/5_fam_banking_principles_conventional_and_islamic.pdf",
        course: "General",
        categories: ["FA Modules HH"],
      },
      {
        label: "6. Basic Principles of Insurance and Takaful",
        url:
          "fa-skill/household/6_fam_basic_principles_of_insurance_takaful.pdf",
        course: "General",
        categories: ["FA Modules HH"],
      },
      {
        label: "7. General Investment",
        url: "fa-skill/household/7_fam_general_investment.pdf",
        course: "General",
        categories: ["FA Modules HH"],
      },
      {
        label: "8. Financial Scams",
        url: "fa-skill/household/8_fam_financial_scams.pdf",
        course: "General",
        categories: ["FA Modules HH"],
      },
      {
        label: "9. Complaint Management",
        url: "fa-skill/household/9_fam_complaint_management.pdf",
        course: "General",
        categories: ["FA Modules HH"],
      },
      {
        label: "10. Recovery Action, Bankruptcy and Property Foreclosure",
        url:
          "fa-skill/household/10_fam_recovery_action_bankruptcy_and_property_foreclosure.pdf",
        course: "General",
        categories: ["FA Modules HH"],
      },
      {
        label: "Reverse Mortgage/Skim Saraan Bercagar (SSB) Module",
        url: "fa-skill/reverse_mortgage/SSB_Module.pdf",
        course: "-",
        categories: ["Reverse Mortgage"],
      },
      {
        label: "Personalised Financial Plan (PFP) - Working Guide",
        url: "fa-skill/pfp/personalised-financial-plan-pfp-working_guide.pdf",
        course: "-",
        categories: ["PFP Modules"],
      },
    ];
    return {
      modules,
    };
  },
  // setup() {
  //   return {};
  // },
  methods: {
    init() {
      setCurrentPageBreadcrumbs("Modules", ["Financial Skills"]);
    },
    onSubmit(values) {
      console.log(values);
    },
  },
});
